@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #050505;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ::-webkit-scrollbar {
    width: 6px;
} */

/* ::-webkit-scrollbar-thumb {
    background-color: rgba(58, 58, 58, 0.6);
    /* border: 2px solid #555555; */
/* border-radius: 10px; */
/* } */
