.shaka-player-container .shaka-controls-button-panel button:hover.material-icons-round {
    color: rgba(0, 0, 0, 0.5) !important;
}

.shaka-bottom-controls {
    /* overflow: visible; */
    bottom: 0;
    pointer-events: auto;
}

.shaka-controls-button-panel {
    position: relative;
    bottom: 0;
}

.shaka-controls-container {
    display: block;
    z-index: 6 !important;
    pointer-events: none;
    min-height: 12.5em !important;
}

/* .shaka-controls-button-panel:before {
    content: url('../../assets/ic_small_logo.svg');
    position: absolute;
    width: 5em;
    top: -2em;
    height: 5em;
    z-index: 5;
} */

.shaka-player-container input[type='range']::-webkit-slider-thumb {
    background: #fff;
    cursor: pointer;
}
.shaka-player-container input[type='range']::-moz-range-thumb {
    background: #fff;
    cursor: pointer;
}
.shaka-player-container input[type='range']::-ms-thumb {
    background: #fff;
    cursor: pointer;
}
.shaka-player-container .shaka-play-button[icon='pause'],
.shaka-player-container .shaka-play-button[icon='play'] {
    background-color: #ffffff;
}

.shaka-player-container .shaka-overflow-menu,
.shaka-player-container .shaka-settings-menu {
    background: #ffffff;
    pointer-events: all;
}
.shaka-player-container .shaka-overflow-menu span + span {
    font-size: 80%;
    color: #000;
    padding-top: 2px;
}
.shaka-player-container .shaka-overflow-menu button:hover,
.shaka-player-container .shaka-settings-menu button:hover {
    background: #ffffff;
    cursor: pointer;
}
.shaka-player-container .shaka-overflow-menu button:hover label,
.shaka-player-container .shaka-settings-menu button:hover label {
    cursor: pointer;
}
.shaka-player-container .shaka-overflow-menu button:focus,
.shaka-player-container .shaka-settings-menu button:focus {
    background: #ffffff;
    border: none;
    outline: none;
}
.shaka-player-container .shaka-overflow-menu button,
.shaka-player-container .shaka-settings-menu button {
    color: #000;
}
.shaka-player-container .shaka-captions-off {
    color: #bfbfbf;
}
.shaka-player-container .shaka-controls-button-panel > button {
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    width: 40px;
    height: 30px;
}
.shaka-player-container .shaka-controls-button-panel > button.shaka-current-time {
    position: relative;
    left: 10px;
    z-index: 1000;
    color: #000;
    pointer-events: none;
    background-color: transparent;
    height: auto !important;
    width: auto !important;
    font-size: 12px;
}
.shaka-player-container .shaka-bottom-controls {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 94%;
    padding-bottom: 0;
    margin-bottom: 3%;
}
.shaka-player-container .shaka-bottom-controls .shaka-controls-button-panel {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 30px;
}
.shaka-player-container .shaka-bottom-controls .shaka-seek-bar-container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 130px 0 40px;
    height: 30px;
    border-radius: 0;
}
.shaka-player-container .shaka-bottom-controls .shaka-mute-button {
    z-index: 1000;
    position: relative;
    width: 50px;
}
.shaka-player-container .shaka-bottom-controls .shaka-seek-bar {
    cursor: pointer;
    height: 30px;
    opacity: 0;
}
.shaka-player-container .shaka-bottom-controls .shaka-small-play-button {
    -webkit-border-top-left-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-bottomleft: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.shaka-player-container .shaka-bottom-controls .shaka-overflow-menu-button {
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    -moz-border-radius-topright: 20px;
    -moz-border-radius-bottomright: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.shaka-player-container .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container {
    z-index: 10;
    left: 0;
    opacity: 0;
    width: 0px;
    -webkit-transition: width 0.2s cubic-bezier(0.4, 0, 1, 1);
    transition: width 0.2s cubic-bezier(0.4, 0, 1, 1);
    position: relative;
    height: 30px;
    padding: 0;
    margin: 0;
    border-radius: 0;
    will-change: width;
}
.shaka-player-container .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container:hover,
.shaka-player-container .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container:focus {
    display: block;
    width: 50px;
    opacity: 1;
    padding: 0;
}
.shaka-player-container .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    left: -6px;
    top: 50%;
    margin-top: -1px;
    background-color: rgba(255, 255, 255, 0.2);
}
.shaka-player-container .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container .shaka-volume-bar {
    height: 30px;
    top: 0;
    border-radius: 0;
}
.shaka-player-container .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container input[type='range'] {
    -webkit-appearance: none;
    background: transparent;
    margin-top: 0;
    border-radius: 0 !important;
    cursor: pointer;
}
.shaka-player-container .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container input[type='range']:focus {
    outline: none;
}
.shaka-player-container .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid rgba(0, 0, 0, 0.5);
    background: transparent;
    margin-top: 0;
    border-radius: 0 !important;
}
.shaka-player-container .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container input[type='range']::-ms-track {
    -webkit-appearance: none;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid rgba(0, 0, 0, 0.5);
    background: transparent;
    margin-top: 0;
    border-radius: 0 !important;
}
.shaka-player-container .shaka-bottom-controls .shaka-mute-button:hover + div {
    opacity: 1;
    width: 50px;
    padding: 0;
}

.shaka-player-container .shaka-controls-button-panel .material-icons-round {
    z-index: 3;
    color: #000000;
}

.shaka-player-container .shaka-current-time {
    color: #000000;
}

#myLogo {
    position: absolute;
    width: 1.5em;
    right: 1em;
    top: 1em;
}
